<template>
    <div v-for="item in dataIndex.data.sidebar" :key="`${item.section}`" :class="`--section-${item.section}`" class="pr--sidebar-section">
        <div v-if="item.title !== null" class="title-uppercase pt-1 pb-0 pr-4 pl-4 border-bottom text-muted small">
            {{ item.title }}
        </div>
        <ul v-if="item.fields !== undefined" :class="`nav nav--middle --block`">
            <li v-for="(field, key) in getFields(item)" :key="`section-${key}`">
                <LayoutSidebarMenuLink v-if="field.type === 'link'" :field="field" :section="item" />
                <LayoutSidebarMenuCollapse
                    v-if="field.children !== undefined && field.children.length"
                    :field="field"
                    :section="item"
                    :is-opened-default="field.opened"
                    :on-change="onChange"
                />
            </li>
            <li v-if="item.fields.length > item.options.limit || item.options.limit__backup !== undefined">
                <button class="btn btn-link d-flex align-items-center position-relative --sidebar-more collapse--has-arrow" type="button" @click="toggleFields(item)">
                    <span v-if="item.options.limit__backup === undefined" class="d-flex align-items-center">
                        Показать ещё {{ item.fields.length - item.options.limit }}
                    </span>
                    <span v-if="item.options.limit__backup !== undefined" class="d-flex align-items-center">
                        Скрыть {{ item.fields.length - item.options.limit__backup }}
                    </span>
                    <font-awesome-icon :icon="`chevron-down`" :class="`${item.options.limit__backup !== undefined ? 'opened' : ''}`" />
                </button>
            </li>
        </ul>
<!--        <div v-if="hasPageMenu(item)" class="title-uppercase pt-1 pb-0 pr-4 pl-4 border-bottom text-muted small">-->
<!--            {{ pageShowStore.data.section === null ? pageShowStore.data.title : pageShowStore.data.section.title }}-->
<!--        </div>-->
<!--        <ul v-if="hasPageMenu(item)" class="nav nav&#45;&#45;middle &#45;&#45;block border-top">-->
<!--            <li v-if="pageShowStore.data.section === null">-->
<!--                <LayoutSidebarMenuLink :field="{ icon: 'minus', title: pageShowStore.data.title, count: null, image: null, url: `/${pageShowStore.data.slug}`}" :section="null" />-->
<!--            </li>-->
<!--            <li v-if="pageShowStore.data.section !== null">-->
<!--                <LayoutSidebarMenuLink :field="{ icon: 'minus', title: pageShowStore.data.section.title, count: null, image: null, url: `/${pageShowStore.data.section.slug}`}" :section="null" />-->
<!--            </li>-->
<!--            <li v-for="page in getPageFields()" :key="page.id">-->
<!--                <LayoutSidebarMenuLink :field="{ icon: 'minus', title: page.title, count: null, image: null, url: `/${page.slug}`}" :section="null" />-->
<!--            </li>-->
<!--        </ul>-->
    </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import LayoutSidebarMenuCollapse from '@/components/Layout/Sidebar/Menu/LayoutSidebarMenuCollapse.vue';
import LayoutSidebarMenuLink from '@/components/Layout/Sidebar/Menu/LayoutSidebarMenuLink.vue';
import serialize from '@/plugins/common/serialize';
import { useStoreAppDataIndex } from '@/stores/app/data';
import { useStoreInterface } from '@/stores/app/interface';
import { useStorePagesShow } from '@/stores/pages/show';
import { useStoreSystem } from '@/stores/system';

export default defineComponent({
    name: 'LayoutSidebarMenu',
    components: {
        LayoutSidebarMenuCollapse,
        LayoutSidebarMenuLink,
    },
    setup() {
        const dataIndex = useStoreAppDataIndex();
        const system = useStoreSystem();
        const pageShowStore = useStorePagesShow();
        const storeInterface = useStoreInterface();
        const timer = null;
        const sectionsOpened = ref({});

        function onChange(field, val) {
            const closed = storeInterface.sidebar.closed;
            if (val) {
                const index = closed.indexOf(field.name);
                if (index > -1) {
                    closed.splice(index, 1);
                }
            } else if (!closed.includes(field.name)) {
                closed.push(field.name);
            }
            storeInterface.setSidebarClosed(closed);
        }

        //
        // updateOpened();

        return {
            dataIndex,
            pageShowStore,
            system,
            timer,
            sectionsOpened,
            storeInterface,
            onChange,
        };
    },
    methods: {
        prepareQuery(query) {
            const self = this;
            if (query !== undefined && query !== null && Object.keys(query).length) {
                Object.keys(query).forEach((key) => {
                    if (query[key] === ':auth_id') {
                        query[key] = self.user.data.id;
                    }
                });
            }
            return query;
        },
        getUrl(field, query) {
            const self = this;
            query = self.prepareQuery(query);
            const q = query !== null ? query : {};
            //q.locale = 'ru';
            return field.url + '?' + serialize.query(q);
        },
        getFields(item) {
            const opened = this.storeInterface.sidebar.opened;
            const closed = this.storeInterface.sidebar.closed;
            // console.log('opened', opened);
            // console.log('closed', closed);
            const fields = item.fields;
            fields.forEach((field) => {
                if (field.type === 'button-query') {
                    if (opened.includes(field.name)) {
                        field.opened = true;
                    }
                    field.children.forEach((child, key) => {
                        if (child.type === 'button-query') {
                            if (opened.includes(child.name)) {
                                field.children[key].opened = true;
                            }
                        }
                    });
                }
            });
            fields.forEach((field) => {
                if (field.type === 'button-query') {
                    if (closed.includes(field.name)) {
                        field.opened = false;
                    }
                    field.children.forEach((child, key) => {
                        if (child.type === 'button-query') {
                            if (closed.includes(child.name)) {
                                field.children[key].opened = false;
                            }
                        }
                    });
                }
            });
            const f = fields.concat();
            if (f.length > item.options.limit) {
                return f.splice(0, item.options.limit);
            }
            return f;
        },
        toggleFields(item) {
            if (item.options.limit__backup === undefined) {
                item.options.limit__backup = item.options.limit;
                item.options.limit = 100;
            } else {
                item.options.limit = item.options.limit__backup;
                item.options.limit__backup = undefined;
            }
        },
        getPageFields() {
            return this.dataIndex.data.pages.pages.filter(x => x.section !== null && (x.section.id === this.pageShowStore.data.id || (this.pageShowStore.data.section !== null && x.section.id === this.pageShowStore.data.section.id)));
        },
        hasPageMenu(item) {
            if (item.section !== 'index') {
                return false;
            }
            if (this.pageShowStore.data === null) {
                return false;
            }
            if (!this.getPageFields().length) {
                return false;
            }
            if (this.system.route.fullPath === `/${this.pageShowStore.data.slug}`) {
                return true;
            }
            return false;
        },
        getPageWithChildren() {
            const page = this.dataIndex.data.pages.pages;
        }
    }
});
</script>
