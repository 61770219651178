<template>
    <BaseCollapse :opened-default="$route.path.startsWith(field.url) || isOpenedDefault" :on-change="onChange" :field="field">
        <template #button="{ change, opened }">
            <LayoutSidebarMenuQuery
                v-if="field.type === 'button-query' && field.children !== undefined && field.children.length !== 0"
                :section="section"
                :field="field"
                :on-change="change"
                :opened="opened"
            />
            <LayoutSidebarMenuLink v-if="field.type === 'link'" :field="field" :section="section" :closed="closed" />
        </template>
        <template #collapse>
            <ul>
                <li v-for="(children, keyC) in field.children" :key="`section-children-${keyC}`" :class="`collapse--${field.type} ${children.children !== undefined && children.children.length ? 'collapse--children' : ''}`">
                    <button
                        v-if="$route.path.startsWith('/' + children.model) && children.type === 'link'"
                        type="button"
                        :class="`btn btn-link d-flex ${false ? 'active' : ''}`"
                        @click="clickSub(children.model, children.query)"
                    >
                        <span v-if="!closed">
                            {{ children.title }} <span v-if="children.count !== null" class="badge badge-sidebar rounded-pill">{{ children.count }}</span>
                        </span>
                    </button>
                    <NuxtLink v-if="!$route.path.startsWith('/' + children.model) && children.type === 'link'" :to="getUrl(children, children.query)" :class="`btn btn-link d-flex ${false ? 'active' : ''}`">
                        {{ children.title }} <span v-if="children.count !== null" class="badge badge-sidebar rounded-pill">{{ children.count }}</span>
                    </NuxtLink>
                    <div v-if="children.type !== 'link'">
                        <LayoutSidebarMenuCollapse
                            v-if="children.children !== undefined && children.children.length"
                            :field="children"
                            :section="section"
                            :is-opened-default="children.opened"
                            :on-change="onChange"
                        />
                    </div>
                </li>
            </ul>
        </template>
    </BaseCollapse>
</template>

<script>
import { defineComponent } from 'vue';
import BaseCollapse from '@/components/Base/Collapse/BaseCollapse.vue';
import LayoutSidebarMenuLink from '@/components/Layout/Sidebar/Menu/LayoutSidebarMenuLink.vue';
import LayoutSidebarMenuQuery from '@/components/Layout/Sidebar/Menu/LayoutSidebarMenuQuery.vue';
import serialize from '@/plugins/common/serialize';

export default defineComponent({
    name: 'LayoutSidebarMenuCollapse',
    components: {
        LayoutSidebarMenuLink,
        LayoutSidebarMenuQuery,
        BaseCollapse,
    },
    props: {
        field: {
            type: [Object],
            required: true,
        },
        section: {
            type: [Object],
            required: true,
        },
        closed: {
            type: [Boolean],
            default: () => {
                return false;
            },
        },
        isOpenedDefault: {
            type: [Boolean],
            required: true,
            default: () => false,
        },
        onChange: {
            type: [Function],
            required: true,
        },
    },
    setup(props) {
        function changed(callback) {
            console.log('changed collapse');
            callback();
            //props.onChange();
        }

        return {
            changed,
        };
    },
    methods: {
        prepareQuery(query) {
            const self = this;
            if (query !== undefined && query !== null && Object.keys(query).length) {
                Object.keys(query).forEach((key) => {
                    if (query[key] === ':auth_id') {
                        query[key] = self.user.data.id;
                    }
                });
            }
            return query;
        },
        getUrl(field, query) {
            const self = this;
            query = self.prepareQuery(query);
            const q = query !== null ? query : {};
            //q.locale = 'ru';
            return field.url + '?' + serialize.query(q);
        },
        clickSub(model, query) {
            console.log(model, query);
        },
    }
});
</script>
