<template>
    <button
        v-if="field.type === 'button-query' && field.children !== undefined && field.children.length !== 0"
        type="button"
        class="btn btn-link collapse--has-arrow"
        @click="onChange"
    >
        <span class="d-flex align-items-center">
            <span v-if="field.icon !== null" class="d-flex align-items-center justify-content-center mr-1" style="width: 26px;">
                <IconFontAwesome v-if="!field.icon.startsWith('<svg')" :name="field.icon" />
<!--                <font-awesome-icon v-if="!field.icon.startsWith('<svg')" :icon="field.icon" />-->
                <span v-if="field.icon.startsWith('<svg')" class="w-100" v-html="field.icon" />
            </span>
            <span v-if="field.image !== undefined && field.image !== null" class="d-flex align-items-center justify-content-center mr-1" style="width: 26px;margin-left: 0.25rem;">
                <img
                    :src="field.image"
                    alt=""
                    height="24"
                    width="24"
                    :class="`pr--image-sidebar-icon ${field.image__circle ? '__circle' : ''} ${field.image__square ? '__square' : ''}`"
                >
            </span>
            {{ field.title }}
        </span>
        <IconFontAwesome :name="'chevron-left'" :class="`${opened ? 'opened' : ''}`" />

<!--        <font-awesome-icon :icon="`chevron-left`" :class="`${opened ? 'opened' : ''}`" />-->
    </button>
</template>

<script>
import { defineComponent } from 'vue';
import IconFontAwesome from '@/components/Icons/IconFontAwesome.vue';

export default defineComponent({
    name: 'LayoutSidebarMenuQuery',
    components: {
        IconFontAwesome
        //
    },
    props: {
        field: {
            type: [Object],
            required: true,
        },
        section: {
            type: [Object],
            required: true,
        },
        onChange: {
            type: [Function],
            required: true,
        },
        opened: {
            type: [Boolean],
            required: true,
        },
    },
    setup() {
        return {
            //
        };
    },
    methods: {
        //
    }
});
</script>
