<template>
    <slot name="button" :change="change" :opened="opened" />
    <div ref="collapse" :class="`collapse ${opened ? 'show' : ''}`" data-bs-parent="#myGroup">
        <slot name="collapse" />
    </div>
</template>

<script>
import { defineComponent, ref, toRefs } from 'vue';

export default defineComponent({
    name: 'BaseCollapse',
    props: {
        openedDefault: {
            type: [Boolean],
            default: () => false,
        },
        onChange: {
            type: [Function],
            default: () => {},
        },
        field: {
            type: [Object],
            default: () => null,
        },
    },
    setup(props) {
        const {
            openedDefault,
        } = toRefs(props);
        const opened = ref(false);
        const collapse = ref(null);

        opened.value = openedDefault.value;
        //
        // console.log(parent);
        //
        // watchEffect(() => {
        //     console.log(collapse.value) // => null
        //     new Collapse(collapse.value, {
        //         toggle: false,
        // });
        return {
            collapse,
            opened,
        };
    },
    mounted() {
        // const button = this.$slots['button'][0].elm;
        // console.log(button);
        // button.classList.add('collapse')
        // button.setAttribute('id', this.id);
        // button.setAttribute('data-bs-target', '#' + this.id);
        // button.setAttribute('data-bs-toggle','collapse');
        // new Collapse(button, {
        //     toggle: this.toggle
        // })
    },
    methods: {
        change() {
            this.opened = !this.opened;
            this.onChange(this.field, this.opened);
        }
    }
});
</script>
