<template>
    <NuxtLink
        v-tippy="{ placement :'right', offset: [0, -10] }"
        :to="getUrl(field, {})"
        :class="`btn btn-link d-flex align-items-center position-relative ${$route.path.endsWith(field.url) ? 'active' : ''}`"
        :content="field.title"
    >
        <span v-if="field.icon !== null" class="d-flex align-items-center justify-content-center mr-1" style="width: 26px;">
            <IconFontAwesome v-if="!field.icon.startsWith('<svg')" :name="field.icon" :class="`${closed ? 'icon-md' : ''}`" />
            <!--            <nuxt-icon v-if="!field.icon.startsWith('<svg')" :name="`fontawesome/${field.icon}`" />-->
            <!--            <font-awesome-icon v-if="!field.icon.startsWith('<svg') && field.icon !== 'home'" :icon="field.icon" />-->
            <span v-if="field.icon.startsWith('<svg')" class="w-100" v-html="field.icon" />
        </span>
        <span v-if="field.image !== null" class="d-flex align-items-center justify-content-center mr-1" style="width: 26px;margin-left: 0.25rem;">
            <img
                :src="field.image"
                :alt="field.title"
                height="24"
                width="24"
                :class="`pr--image-sidebar-icon ${field.image__circle ? '__circle' : ''} ${field.image__square ? '__square' : ''}`"
            >
        </span>
        <span v-if="!textIsLonger(field) && !closed" class="--sidebar-title">
            {{ field.title }}
        </span>
        <span v-if="textIsLonger(field) && !closed" class="--sidebar-title">
            {{ textLimited }}
        </span>
        <!-- &nbsp; -->
        <span v-if="field.count !== null" class="badge badge-sidebar rounded-pill">{{ field.count }}</span>
    </NuxtLink>
</template>

<script setup lang="ts">
import { defineComponent, defineProps } from 'vue';
import IconFontAwesome from '@/components/Icons/IconFontAwesome.vue';
import serialize from '@/plugins/common/serialize';

export interface Props {
    field?: object|null,
    section?: object|null,
    closed?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
    field: null,
    section: null,
    closed: false,
});

function getUrl(field, query) {
    query = prepareQuery(query);
    const q = query !== null ? query : {};
    //q.locale = 'ru';
    return field.url + '?' + serialize.query(q);
}

function prepareQuery(query) {
    if (query !== undefined && query !== null && Object.keys(query).length) {
        Object.keys(query).forEach((key) => {
            if (query[key] === ':auth_id') {
                //query[key] = self.user.data.id;
            }
        });
    }
    return query;
}

// asd
function textIsLonger(field) {
    return field.title.length > 54;
}
function textLimited(field) {
    return field.title.substring(0, 48) + '...';
}
</script>
