import { defineStore, acceptHMRUpdate } from 'pinia';
import crpyto from '@/plugins/common/crpyto';
import cookiesPinia from '@/plugins/cookies/pinia';
import useAppLogger from '@/composables/useAppLogger';

interface State {
    sidebar: {
        opened: string[],
        closed: string[],
    };
}

export const useStoreInterface = defineStore({
    id: 'app/interface',

    state: (): State => ({
        sidebar: {
            opened: [],
            closed: [
                //'vuejs'
            ],
        },
    }),

    persist: {
        key: cookiesPinia.NAMES.INTERFACE,
        // key: (id: string) => `${cookiesPinia.NAMES.INTERFACE}_${id}`,
        debug: true,
        // @ts-expect-error
        // storage: persistedState.localStorage,
        storage: persistedState.cookiesWithOptions(cookiesPinia.OPTIONS),
        serializer: {
            deserialize: (value: string) => {
                useAppLogger().piniaLocalStorage('config:deserialize', value, JSON.parse(value));
                return JSON.parse(value);
                // return JSON.parse(crpyto.decrypt(value));
            },
            serialize: (value: any) => {
                useAppLogger().piniaLocalStorage('config:serialize', value, JSON.stringify(value));
                return JSON.stringify(value);
                // return crpyto.encrypt(JSON.stringify(value));
            },
        },
        beforeRestore: (ctx: any) => {
            useAppLogger().piniaLocalStorage(`config:beforeRestore '${ctx.store.$id}'`);
        },
        afterRestore: (ctx: any) => {
            useAppLogger().piniaLocalStorage(`config:afterRestore '${ctx.store.$id}'`);
        }
    },

    getters: {
        //
    },

    actions: {
        clear() {
            //
        },
        clearSidebarOpened() {
            this.sidebar.opened = [];
        },
        setSidebarOpened(val: []) {
            this.sidebar.opened = val;
        },
        setSidebarClosed(val: []) {
            this.sidebar.closed = val;
        },
        mergeSidebarOpened(value: string) {
            if (!this.sidebar.opened.includes(value)) {
                this.sidebar.opened.push(value);
            }
        },
        mergeSidebarClosed(value: string) {
            if (!this.sidebar.closed.includes(value)) {
                this.sidebar.closed.push(value);
            }
        }
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useStoreInterface, import.meta.hot));
}
